import { StorageTableNames } from '@/enums/storage';
import { Result, err, ok } from '@/result';
import { TicketCheckin } from '@/types/checkIn';

export const getLastCheckinAttemptsViaCursor = async (
  db: IDBDatabase,
  scanCode: string,
  count: number,
): Promise<Result<TicketCheckin[], Error>> => {
  return new Promise((resolve) => {
    const transaction = db.transaction(StorageTableNames.TICKET_CHECKINS);
    const ticketStore = transaction.objectStore(StorageTableNames.TICKET_CHECKINS);
    const ticketCheckinsStatusIndex = ticketStore.index('checkinTimestamp');

    const request = ticketCheckinsStatusIndex.openCursor(undefined, 'prev');
    const result: TicketCheckin[] = [];

    request.addEventListener('success', (event) => {
      if (event.target === null) {
        return;
      }

      if (!(event.target instanceof IDBRequest)) {
        resolve(err(new Error(`Unexpected event target type`)));
        return;
      }

      const cursor = event.target.result;

      if (cursor === null) {
        // no more results - resolve with what we have
        resolve(ok(result));
        return;
      }

      if (!(cursor instanceof IDBCursorWithValue)) {
        resolve(err(new Error(`Unexpected cursor type`)));
      }

      if (result.length < count && cursor.value.scanCode === scanCode) {
        result.push(cursor.value);
        cursor.continue();
      } else {
        resolve(ok(result));
      }
    });

    request.addEventListener('error', () => {
      resolve(err(request.error ?? new Error('Unknown error')));
    });
  });
};

export const getAlreadyCheckedInTicketCountViaCursor = async (
  db: IDBDatabase,
  scanCode: string,
): Promise<Result<number, Error>> => {
  return new Promise((resolve) => {
    const transaction = db.transaction(StorageTableNames.TICKETS);
    const ticketStore = transaction.objectStore(StorageTableNames.TICKETS);
    const ticketCheckinsStatusIndex = ticketStore.index('scanCode_ticketCode_checkedIn');

    const request = ticketCheckinsStatusIndex.openCursor(undefined, 'prev');
    let result = 0;

    request.addEventListener('success', (event) => {
      if (event.target === null) {
        return;
      }

      if (!(event.target instanceof IDBRequest)) {
        resolve(err(new Error(`Unexpected event target type`)));
        return;
      }

      const cursor = event.target.result;

      if (cursor === null) {
        // no more results - resolve with what we have
        resolve(ok(result));
        return;
      }

      if (!(cursor instanceof IDBCursorWithValue)) {
        resolve(err(new Error(`Unexpected cursor type`)));
      }

      if (cursor.value.scanCode === scanCode) {
        result++;
      }
      cursor.continue();
    });

    request.addEventListener('error', () => {
      resolve(err(request.error ?? new Error('Unknown error')));
    });
  });
};
