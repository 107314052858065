import { InvalidResponseeError } from '@/customErrors/invalidResponse';
import {
  CheckInDocument,
  FetchTicketsDocument,
  SourceCheckins,
  ValidateScanCodeAndGetEventInfosDocument,
} from '@/graphql/generated/graphql';
import { err, exceptionToErrorResult, ok } from '@/result';
import { TicketSource } from '@/ticket-source/TicketSource';
import { getCurrentTimestampInSeconds } from '@/utils/date';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';

export const createGraphQLTicketSource = (apollo: ApolloClient<NormalizedCacheObject>): TicketSource => {
  const validateScanCodeAndGetEventInfos: TicketSource['validateScanCodeAndGetEventInfos'] = async (scanCode) => {
    return exceptionToErrorResult(async () => {
      const { data, error } = await apollo.query({
        fetchPolicy: 'no-cache', // we can't use the cache here - the name and the hideTargetQuantity can change for a scanCode
        query: ValidateScanCodeAndGetEventInfosDocument,
        variables: { scanCode },
      });
      if (error !== undefined) {
        return err(error);
      }
      if (data.source.name[0] === undefined) {
        return err(new Error('Invalid response'));
      }

      return ok({
        ...data.source.name[0],
        hideTargetQuantity: data.source.hideTargetQuantity === null ? false : data.source.hideTargetQuantity,
      });
    });
  };

  const fetchTickets: TicketSource['fetchTickets'] = async (scanCode, lastFingerprint, cacheFirst) => {
    return exceptionToErrorResult(async () => {
      const { data, error } = await apollo.query({
        fetchPolicy: cacheFirst === true ? 'cache-first' : 'network-only',
        query: FetchTicketsDocument,
        variables: { scanCode, lastFingerprint },
      });
      if (error !== undefined) {
        return err(error);
      }
      return ok([data.source.changed.targets, data.source.changed.fingerprint] as const);
    });
  };

  const checkInTicketCodeAndGetTicketInfos: TicketSource['checkInTicketCodeAndGetTicketInfos'] = async (
    scanCode,
    ticketCode,
  ) => {
    return exceptionToErrorResult(async () => {
      const actualTimestamp = getCurrentTimestampInSeconds();
      const checkins: SourceCheckins[] = [{ scanCode, checkins: [{ code: ticketCode, timestamp: actualTimestamp }] }];
      const { data } = await apollo.mutate({
        mutation: CheckInDocument,
        variables: { checkins },
      });

      // check if we get a valid data response - if we only get errors back we also throw InvalidResponseeError
      if (data === undefined || data === null) {
        return err(new InvalidResponseeError());
      }
      // check if we get an error from the server
      if (data.checkIn[0]?.success === false) {
        if (data.checkIn[0].failureReason !== null) {
          return err(new Error(data.checkIn[0].failureReason));
        }
        // TODO hier ggf spezifischer werden - als default wird aktuell immer "Ticket not found" ausgegeben
        return err(new Error());
      }
      return ok(actualTimestamp);
    });
  };

  const uploadTicketCheckins: TicketSource['uploadTicketCheckins'] = async (checkins: SourceCheckins[]) => {
    return exceptionToErrorResult(async () => {
      const { data } = await apollo.mutate({
        mutation: CheckInDocument,
        variables: { checkins },
      });

      // check if we get a valid data response - if we only get errors back we also throw InvalidResponseeError
      if (data === undefined || data === null) {
        return err(new InvalidResponseeError());
      }
      return ok(data.checkIn);
    });
  };

  return { checkInTicketCodeAndGetTicketInfos, fetchTickets, uploadTicketCheckins, validateScanCodeAndGetEventInfos };
};
