import { clientConfig } from '@/config/client.config';
import { ApolloClient, createHttpLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client';

// INFO if we want to use apollo on the server side, we have to adjust the initialization, so that single requests do not share an apollo cache (also note https://www.apollographql.com/docs/react/performance/server-side-rendering/#server-side-rendering)
let apolloClient: ApolloClient<NormalizedCacheObject> | null = null;

export const createApolloClient = () => {
  if (apolloClient === null) {
    const link = createHttpLink({ uri: clientConfig.graphql.endpoint });
    const cache = new InMemoryCache();
    apolloClient = new ApolloClient({ cache, link });
  }
  return apolloClient;
};
