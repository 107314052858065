import { EventStorage, createIndexedDBEventStorage } from '@/persistence/event';

let indexedDBStorage: EventStorage | null = null;

export const getStorageWrapper = () => {
  if (indexedDBStorage === null) {
    indexedDBStorage = createIndexedDBEventStorage();
  }
  return indexedDBStorage;
};
