import { createApolloClient } from '@/graphql/apollo-client';
import { TicketSource } from '@/ticket-source/TicketSource';
import { createGraphQLTicketSource } from '@/ticket-source/graphql';

let ticketSourceDataWrapper: TicketSource | null = null;

// abstract createApolloClient - this is the only place where we need to import apollo-client & createGraphQLTicketSource
const createTicketSourceDataWrapper = () => {
  return createGraphQLTicketSource(createApolloClient());
};

export const getTicketSourceDataWrapper = () => {
  if (ticketSourceDataWrapper === null) {
    ticketSourceDataWrapper = createTicketSourceDataWrapper();
  }
  return ticketSourceDataWrapper;
};
