import { StorageTableNames } from '@/enums/storage';
import { TicketCheckinUploadStatus } from '@/enums/tickets';
import { EventStorage } from '@/persistence/event/EventStorage';
import {
  getAlreadyCheckedInTicketCountViaCursor,
  getLastCheckinAttemptsViaCursor,
} from '@/persistence/event/supportFunctions';
import { INDEXED_DB_NAME, idbResult } from '@/persistence/indexeddb';
import { err, ok } from '@/result';
import { ScanCode, TicketCode } from '@/types/ticket';
import { z } from 'zod';

declare global {
  interface IDBObjectStore {
    get(query: IDBValidKey | IDBKeyRange): IDBRequest<unknown>;
  }
}

const DB_VERSION = 4;

const buildTicketStorageKey = (scanCode: ScanCode, ticketCode: TicketCode) => `${scanCode}-${ticketCode}`;
const buildTicketCheckinStorageKey = (scanCode: ScanCode, ticketCode: TicketCode, timestamp: number) =>
  `${scanCode}-${ticketCode}-${timestamp}`;

const dbTicketSchema = z.object({
  checkin: z.number().nullable(),
  color: z.string().nullable(),
  info: z.string().nullable(),
  isRevoked: z.boolean(),
  scanCode: z.string().min(1),
  ticketCode: z.string().min(1),
  typeLocale: z.string().min(1),
  typeText: z.string().min(1),
  validityIntervals: z
    .array(
      z.object({
        end: z.string(),
        endTimestampInSec: z.number(),
        start: z.string(),
        startTimestampInSec: z.number(),
      }),
    )
    .optional(),
});

export const createIndexedDBEventStorage = (): EventStorage => {
  const dbPromise = (async () => {
    const openRequest = indexedDB.open(INDEXED_DB_NAME, DB_VERSION);
    openRequest.addEventListener('upgradeneeded', (e) => {
      const req = e.target;
      if (!(req instanceof IDBOpenDBRequest)) {
        return;
      }
      const transaction = req.transaction;
      if (!(transaction instanceof IDBTransaction)) {
        return;
      }

      if (!req.result.objectStoreNames.contains(StorageTableNames.FINGERPRINTS)) {
        req.result.createObjectStore(StorageTableNames.FINGERPRINTS);
      }
      if (!req.result.objectStoreNames.contains(StorageTableNames.TICKETS)) {
        req.result.createObjectStore(StorageTableNames.TICKETS);
      }
      const tickets = transaction.objectStore(StorageTableNames.TICKETS);
      if (!tickets.indexNames.contains('scanCode')) {
        tickets.createIndex('scanCode', 'scanCode', { unique: false });
      }
      if (!tickets.indexNames.contains('scanCode_ticketCode_checkedIn')) {
        tickets.createIndex('scanCode_ticketCode_checkedIn', ['scanCode', 'ticketCode', 'checkin'], { unique: true });
      }

      if (!req.result.objectStoreNames.contains(StorageTableNames.TICKET_CHECKINS)) {
        req.result.createObjectStore(StorageTableNames.TICKET_CHECKINS);
      }
      const ticketCheckins = transaction.objectStore(StorageTableNames.TICKET_CHECKINS);
      if (!ticketCheckins.indexNames.contains('uploadStatus')) {
        ticketCheckins.createIndex('uploadStatus', 'uploadStatus', { unique: false });
      }
      if (!ticketCheckins.indexNames.contains('checkinTimestamp')) {
        ticketCheckins.createIndex('checkinTimestamp', 'checkin', { unique: false });
      }
    });

    return idbResult(openRequest);
  })();

  const saveTicket: EventStorage['saveTicket'] = async (tickets) => {
    const db = await dbPromise;
    if (!db.ok) {
      return err(db.error);
    }
    const transaction = db.data.transaction(StorageTableNames.TICKETS, 'readwrite');
    const ticketStore = transaction.objectStore(StorageTableNames.TICKETS);
    const putPromises = [];
    for (const ticket of tickets) {
      putPromises.push(
        await idbResult(ticketStore.put(ticket, buildTicketStorageKey(ticket.scanCode, ticket.ticketCode))),
      );
    }
    const results = await Promise.allSettled(putPromises);
    const someHaveFailed = results.some((result) => {
      return result.status === 'rejected' || !result.value.ok;
    });
    if (someHaveFailed) {
      transaction.abort();
      return err(new Error('Some tickets could not be saved.'));
    }
    return ok(undefined);
  };

  const findTicket: EventStorage['findTicket'] = async (scanCode, ticketCode) => {
    const db = await dbPromise;
    if (!db.ok) {
      return err(db.error);
    }
    const transaction = db.data.transaction(StorageTableNames.TICKETS);
    const ticketStore = transaction.objectStore(StorageTableNames.TICKETS);
    const result = await idbResult(ticketStore.get(buildTicketStorageKey(scanCode, ticketCode)));

    if (!result.ok) {
      return result;
    }
    const parseResult = dbTicketSchema.safeParse(result.data);
    if (!parseResult.success) {
      return err(parseResult.error);
    }
    return ok({ validityIntervals: [], ...parseResult.data });
  };

  const countTicketsByScanCode: EventStorage['countTicketsByScanCode'] = async (scanCode) => {
    const db = await dbPromise;
    if (!db.ok) {
      return err(db.error);
    }
    const transaction = db.data.transaction(StorageTableNames.TICKETS);
    const ticketStore = transaction.objectStore(StorageTableNames.TICKETS);
    const ticketsScanCodeIndex = ticketStore.index('scanCode');
    const result = await idbResult(ticketsScanCodeIndex.getAll(scanCode));

    if (!result.ok) {
      return result;
    }
    return ok(result.data.length);
  };

  const countAlreadyCheckedInTicketsByScanCode: EventStorage['countAlreadyCheckedInTicketsByScanCode'] = async (
    scanCode,
  ) => {
    const db = await dbPromise;
    if (!db.ok) {
      return err(db.error);
    }
    const result = await getAlreadyCheckedInTicketCountViaCursor(db.data, scanCode);

    if (!result.ok) {
      return result;
    }
    return ok(result.data);
  };

  const getTicketCheckinsByStatus: EventStorage['getTicketCheckinsByStatus'] = async (
    status = TicketCheckinUploadStatus.UNPUSHED,
  ) => {
    const db = await dbPromise;
    if (!db.ok) {
      return err(db.error);
    }
    const transaction = db.data.transaction(StorageTableNames.TICKET_CHECKINS);
    const ticketStore = transaction.objectStore(StorageTableNames.TICKET_CHECKINS);
    const ticketCheckinsStatusIndex = ticketStore.index('uploadStatus');
    const result = await idbResult(ticketCheckinsStatusIndex.getAll(status));

    if (!result.ok) {
      return result;
    }

    return ok(result.data);
  };

  const saveTicketCheckin: EventStorage['saveTicketCheckin'] = async (ticketCheckin) => {
    const db = await dbPromise;
    if (!db.ok) {
      return err(db.error);
    }

    const transaction = db.data.transaction(StorageTableNames.TICKET_CHECKINS, 'readwrite');
    const ticketStore = transaction.objectStore(StorageTableNames.TICKET_CHECKINS);
    const result = await idbResult(
      ticketStore.put(
        ticketCheckin,
        buildTicketCheckinStorageKey(ticketCheckin.scanCode, ticketCheckin.ticketCode, ticketCheckin.checkin),
      ),
    );

    if (!result.ok) {
      return result;
    }
    return ok(undefined);
  };

  const updateTicketCheckins: EventStorage['updateTicketCheckins'] = async (unpushedCheckins, ticketCheckins) => {
    const db = await dbPromise;
    if (!db.ok) {
      return err(db.error);
    }

    const transaction = db.data.transaction(StorageTableNames.TICKET_CHECKINS, 'readwrite');
    const ticketStore = transaction.objectStore(StorageTableNames.TICKET_CHECKINS);

    const putPromises = [];

    for (const unpushed of unpushedCheckins) {
      // TODO klären, ob wir den check machen wollen
      const found = ticketCheckins.find((checkin) => {
        return unpushed.ticketCode === checkin.code;
      });
      if (found === undefined) {
        continue;
      }
      unpushed.uploadStatus = TicketCheckinUploadStatus.PUSHED;
      putPromises.push(
        await idbResult(
          ticketStore.put(
            unpushed,
            buildTicketCheckinStorageKey(unpushed.scanCode, unpushed.ticketCode, unpushed.checkin),
          ),
        ),
      );
    }
    const results = await Promise.allSettled(putPromises);
    const someHaveFailed = results.some((result) => {
      return result.status === 'rejected' || !result.value.ok;
    });
    if (someHaveFailed) {
      transaction.abort();
    }

    return ok(undefined);
  };

  const setFingerprint: EventStorage['setFingerprint'] = async (scanCode, fingerprint) => {
    const db = await dbPromise;
    if (!db.ok) {
      return err(db.error);
    }
    const transaction = db.data.transaction(StorageTableNames.FINGERPRINTS, 'readwrite');
    const result = await idbResult(transaction.objectStore(StorageTableNames.FINGERPRINTS).put(fingerprint, scanCode));
    if (!result.ok) {
      return result;
    }
    return ok(undefined);
  };

  const getFingerprint: EventStorage['getFingerprint'] = async (scanCode) => {
    const db = await dbPromise;
    if (!db.ok) {
      return err(db.error);
    }
    const transaction = db.data.transaction(StorageTableNames.FINGERPRINTS);
    const result = await idbResult(transaction.objectStore(StorageTableNames.FINGERPRINTS).get(scanCode));
    if (!result.ok) {
      return result;
    }
    const parseResult = z.string().or(z.undefined()).safeParse(result.data);
    if (!parseResult.success) {
      return err(parseResult.error);
    }
    return ok(parseResult.data);
  };

  const clearObjectStores: EventStorage['clearObjectStores'] = async () => {
    const db = await dbPromise;
    if (!db.ok) {
      return err(db.error);
    }
    const stores = db.data.objectStoreNames;

    if (stores.length === 0) {
      return ok(undefined);
    }

    const clearPromises = [];
    const storeNames = Array.from(db.data.objectStoreNames);
    const transaction = db.data.transaction(storeNames, 'readwrite');

    for (const storeName of stores) {
      const objectStore = transaction.objectStore(storeName);
      clearPromises.push(await idbResult(objectStore.clear()));
    }

    const results = await Promise.allSettled(clearPromises);
    const someHaveFailed = results.some((result) => {
      return result.status === 'rejected' || !result.value.ok;
    });

    if (someHaveFailed) {
      transaction.abort();
      return err(new Error('Some object stores could not be cleared.'));
    }

    return ok(undefined);
  };

  const getLastCheckinAttempts: EventStorage['getLastCheckinAttempts'] = async (scanCode, count) => {
    const db = await dbPromise;
    if (!db.ok) {
      return err(db.error);
    }
    const result = await getLastCheckinAttemptsViaCursor(db.data, scanCode, count);

    if (!result.ok) {
      return err(new Error('eror during getLastCheckinAttemptsByCursor'));
    }

    return ok(result.data);
  };

  return {
    clearObjectStores,
    countAlreadyCheckedInTicketsByScanCode,
    countTicketsByScanCode,
    findTicket,
    getFingerprint,
    getLastCheckinAttempts,
    getTicketCheckinsByStatus,
    saveTicket,
    saveTicketCheckin,
    setFingerprint,
    updateTicketCheckins,
  };
};
