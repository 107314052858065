import { err, ok, Result } from '@/result';

export const INDEXED_DB_NAME = 'event';

export const idbResult = <T>(request: IDBRequest<T>) =>
  new Promise<Result<T, Error>>((resolve) => {
    request.addEventListener('success', () => {
      resolve(ok(request.result));
    });
    request.addEventListener('error', () => {
      resolve(err(request.error ?? new Error('Unknown error')));
    });
  });
